<template>
  <div>
    <b-tabs>
      <b-tab title="主体信息">
        <customer-entity :company_id="company_id" v-on:push_company_id="getCompanyId($event)"></customer-entity>
      </b-tab>

      <b-tab title="联系人信息">
        <customer-contact :company_id="company_id"></customer-contact>
      </b-tab>

      <b-tab title="银行账户">
        <customer-bak-acc :company_id="company_id"></customer-bak-acc>
      </b-tab>

      <b-tab title="账期信息">
        <customer-warehouse-statement :company_id="company_id"></customer-warehouse-statement>
      </b-tab>

      <b-tab title="证照附件">
        <customer-attachments :company_id="company_id"></customer-attachments>
      </b-tab>
    </b-tabs>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BLink,
  BBadge, BDropdown, BDropdownItem, BPagination, BTabs, BTab,
} from 'bootstrap-vue'
import customerEntity from "@/views/apps/company-new/customer/CustomerEntity";
import customerContact from "@/views/apps/company-new/customer/CustomerContact";
import customerBakAcc from "@/views/apps/company-new/customer/CustomerBakAcc";
import customerWarehouseStatement from "@/views/apps/company-new/customer/CustomerWarehouseStatement";
import customerAttachments from "@/views/apps/company-new/customer/CustomerAttachments";
import {getCode, getCodeColor, getCodeLabel, getCodeOptions, isEmpty} from "@core/utils/filter";
import {ref} from "@vue/composition-api";
export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BTabs,
    BTab,
    customerEntity,
    customerContact,
    customerBakAcc,
    customerWarehouseStatement,
    customerAttachments,
  },
  data() {
    return {
      company_id:ref(0),
    }
  },
  setup() {
    const getCompanyId = function (param){
      this.company_id = param
    }

    return {
      getCompanyId,
    }
  },
  created() {
    this.company_id = Number.parseInt(this.$route.query.id) || 0
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
